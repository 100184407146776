import Component from "../classes/Component";
import { delay, getRandomItem } from "../utils/math";
import { sequences } from "../utils/sequences";

export default class extends Component {
  constructor() {
    super({
      element: "[data-characters]",
    });

    this.sequences = sequences.slice();

    this.shouldSequenceChange = true;
    this.isHovering = false;

    this.init();
  }

  generateRandomSequence() {
    const sequence = this.sequences[getRandomItem(this.sequences.length)];

    this.sequences.splice(this.sequences.indexOf(sequence), 1);

    if (this.sequences.length === 0) {
      this.sequences = sequences.slice();
    }

    return sequence;
  }

  animateSequence() {
    if (!this.shouldSequenceChange) return;
    this.index = 0;

    const sequence = this.generateRandomSequence();

    sequence.forEach(async (item, index) => {
      // sum up all delays before this item
      const totalPrevDelay = sequence
        .slice(0, index)
        .reduce((acc, item) => acc + item.delay, 0);

      this.totalDelay = sequence.reduce((acc, item) => acc + item.delay, 0);

      await delay(totalPrevDelay);

      if (this.isHovering) {
        this.element.innerHTML = item.text;
      }
    });

    this.shouldSequenceChange = false;
    setTimeout(async () => {
      this.index = 0;
      this.shouldSequenceChange = true;

      if (this.isHovering) {
        await delay(this.totalDelay);
        this.animateSequence();
      }
    }, this.totalDelay);
  }

  init() {
    this.element.addEventListener("mouseover", () => {
      this.isHovering = true;
      this.animateSequence();
    });

    this.element.addEventListener("mouseout", () => {
      this.isHovering = false;
      this.element.innerHTML =
        "0/////////////////////////////////////////////////////////////////X////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////X/////////////////////////////////////////////////////////////////0";
    });
  }
}
