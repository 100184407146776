import Component from "../classes/Component";
import { mapEach } from "../utils/dom";
import { delay } from "../utils/math";

export default class Preloader extends Component {
  constructor() {
    super({
      element: ".preloader",
      elements: {
        blocks: ".preloader__block",
        dots: ".dots",
      },
    });

    this.dotsNumber = 6;
    this.createLoader();
  }

  async createLoader() {
    const { blocks, dots } = this.elements;

    this.dotsDelay = this.dotsNumber * 125 * dots.length;

    mapEach(blocks, async (block, index) => {
      const delayTime = Number(block.dataset.delay);

      await delay(delayTime);
      block.classList.add("active");
    });

    mapEach(dots, async (dot, index) => {
      const delayTime = Number(dot.dataset.delay);
      await delay(delayTime);

      const text = dot.innerHTML;
      // create array from dots length
      const dotsArray = Array.from(Array(this.dotsNumber).keys());

      dotsArray.forEach(async (item, index) => {
        await delay(index * 125);
        dot.innerHTML = text + ".".repeat(index + 1);
      });
    });

    this.onLoaded();
  }

  onLoaded() {
    const { blocks, dots } = this.elements;

    const finalDelay =
      Number(blocks[blocks.length - 1].dataset.delay) +
      this.dotsDelay / dots.length;

    setTimeout(() => {
      this.emit("completed");
      this.destroy();
    }, finalDelay);
  }

  destroy() {
    this.element.parentNode.removeChild(this.element);
  }
}
