import Page from "../../classes/Page";
import { BREAKPOINT_TABLET } from "../../utils/breakpoints";
import { mapElements, mapEach } from "../../utils/dom";
import { delay, blockDelay, nestedDelay } from "../../utils/math";

export default class extends Page {
  constructor() {
    super({
      classes: {
        active: "initial--active",
        pageActive: "page--active",
      },
      element: ".initial",
      elements: {
        wrapper: ".initial__wrapper",
      },
    });
  }

  async show() {
    this.element.classList.add(this.classes.active);
    this.element.classList.add(this.classes.pageActive);

    if (window.innerWidth > BREAKPOINT_TABLET) {
      await this.desktopShow();
    } else {
      await this.mobileShow();
    }
  }

  async hide() {
    this.element.classList.remove(this.classes.active);
    this.element.classList.remove(this.classes.pageActive);

    await delay(400);

    return super.hide();
  }

  async desktopShow() {
    const elements = mapElements(".c-main", { blocks: ".initial__block" });

    let delayTime = 0;
    let nestedDelayTime = 0;

    mapEach(elements.blocks, async (element, index) => {
      element.style.cssText = "opacity: 0;";

      if (element.dataset.nested) {
        delayTime += blockDelay;
        const links = element.querySelectorAll(".c-link__parent");

        mapEach(links, async (link, index) => {
          link.style.cssText = "opacity: 0;";
          await delay(delayTime + (index + 1) * nestedDelay);
          link.style.cssText = "opacity: 1;";
        });

        nestedDelayTime += nestedDelay * links.length;
      } else {
        delayTime += blockDelay + nestedDelayTime;
      }

      if (index >= elements.blocks.length - 2) {
        delayTime -= nestedDelayTime;
      }

      await delay(delayTime);
      elements.blocks[index].style.cssText = "opacity: 1;";
    });
  }

  async mobileShow() {
    const sideNav = mapElements(".c-main", { el: ".c-sidebar__nav" });
    const elements = mapElements(".initial__mobile", { blocks: ".c-section" });

    sideNav.el.style.cssText = "opacity: 1;";

    let delayTime = 250;

    mapEach(elements.blocks, async (element, index) => {
      element.style.cssText = "opacity: 0;";

      // delay  elementContent and add delay to next block
      if (index <= 1) {
        delayTime += blockDelay;
      } else {
        delayTime += blockDelay + nestedDelay;
      }

      await delay(delayTime);
      elements.blocks[index].style.cssText = "opacity: 1;";
    });
  }

  onResize() {
    super.onResize();
  }

  update() {
    super.update();
  }
}
