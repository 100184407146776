import Page from "../../classes/Page";
import { delay, blockDelay, nestedDelay } from "../../utils/math";
import { mapEach } from "../../utils/dom";

export default class extends Page {
  constructor() {
    super({
      classes: {
        active: "everything--active",
        pageActive: "page--active",
      },
      element: ".everything",
      elements: {
        wrapper: ".everything__wrapper",
        blocks: ".c-section",
        footer: ".c-section__footer",
        nav: ".c-nav",
        form: ".c-form",
      },
    });
  }

  async show() {
    this.element.classList.add(this.classes.active);
    this.element.classList.add(this.classes.pageActive);
    const { blocks, footer, nav, form } = this.elements;

    let delayTime = 0;
    footer.style.cssText = "opacity: 0;";
    nav.style.cssText = "opacity: 0;";
    form.style.cssText = "opacity: 0;";

    mapEach(blocks, async (element, index) => {
      element.style.cssText = "opacity: 0;";

      delayTime += blockDelay + nestedDelay;

      await delay(delayTime);
      blocks[index].style.cssText = "opacity: 1;";
    });

    await delay(delayTime + blockDelay + nestedDelay);
    footer.style.cssText = "opacity: 1;";

    await delay(1250);
    nav.style.cssText = "opacity: 1;";

    await delay(250);
    form.style.cssText = "opacity: 1;";
  }

  async hide() {
    this.element.classList.remove(this.classes.active);
    this.element.classList.remove(this.classes.pageActive);

    await delay(400);

    return super.hide();
  }

  onResize() {
    super.onResize();
  }

  update() {
    super.update();
  }
}
