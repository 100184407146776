import Page from "../../classes/Page";
import { delay } from "../../utils/math";

export default class extends Page {
  constructor() {
    super({
      classes: {
        active: "tools--active",
        pageActive: "page--active",
      },
      element: ".tools",
      elements: {
        wrapper: ".tools__wrapper",
      },
    });
  }

  show() {
    this.element.classList.add(this.classes.active);
    this.element.classList.add(this.classes.pageActive);

    return super.show();
  }

  async hide() {
    this.element.classList.remove(this.classes.active);
    this.element.classList.remove(this.classes.pageActive);

    await delay(400);

    return super.hide();
  }

  onResize() {
    super.onResize();
  }

  update() {
    super.update();
  }
}
