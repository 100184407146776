export const original =
  "0/////////////////////////////////////////////////////////////////X////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////X/////////////////////////////////////////////////////////////////0";

const sequence1 = [
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 750,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////X/////////////////////////////////////////////////////////////////0",
    delay: 250,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 0,
  },
];

const sequence2 = [
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 100,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X///////////////////////////////////////////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////////////////////////////////////////////////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////////////////////////////////////////////////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////////////////////////////////////////////////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////////////////////////////////////////////////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////////////////////////////////////////////////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////////////////////////////////////////////////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///////////////////////////////////////////|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 100,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 100,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X///////////////////////////////////////////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////////////////////////////////////////////////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////////////////////////////////////////////////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////////////////////////////////////////////////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////////////////////////////////////////////////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////////////////////////////////////////////////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////////////////////////////////////////////////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///////////////////////////////////////////|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 100,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 100,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;/////////////////////////////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;/////////////////////////////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;/////////////////////////////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;/////////////////////////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;/////////////////////////////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;/////////////////////////////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;/////////////////////////////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|//////////////////////X/////////////////////////////////////////////////////////////////0",
    delay: 100,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 100,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;/////////////////////////////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;/////////////////////////////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;/////////////////////////////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;/////////////////////////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;/////////////////////////////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;/////////////////////////////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;/////////////////////////////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|//////////////////////X/////////////////////////////////////////////////////////////////0",
    delay: 100,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 100,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 0,
  },
];

const sequence3 = [
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 100,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 100,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;//////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 100,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 100,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 0,
  },
];

const sequence4 = [
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X///////////&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///\\&nbsp;&nbsp;\\///////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;/////////////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;/////////////&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\///////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;/////////////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;/////////////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;/////////////&nbsp;&nbsp;\\///&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\///////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;//////////////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/////////////////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;/////////////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;/////////////&nbsp;&nbsp;\\///&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\///////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;///////////////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;/|&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///////////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/////////////////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;/////////////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;/////////////&nbsp;&nbsp;\\///&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\///////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;///////////////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;/|&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///////////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/////////////////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;/////////////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;/////////////&nbsp;&nbsp;\\///&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\///////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;///////////////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;/|&nbsp;&nbsp;|/&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///////////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/////////////////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;/////////////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;/////////////&nbsp;&nbsp;\\///&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\///////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;///////////////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;/|&nbsp;&nbsp;|/&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;///X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///////////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/////////////////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;/////////////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;/////////////&nbsp;&nbsp;\\///&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\///////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;/|&nbsp;&nbsp;|/&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;////X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///////////////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/////////////////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;/////////////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;/////////////&nbsp;&nbsp;\\///&nbsp;&nbsp;/|&nbsp;&nbsp;|/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;///X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///////////////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/////////////////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;///////////|&nbsp;&nbsp;|//&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///////////////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|////////////&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/////////////X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 0,
  },
];

const sequence5 = [
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|//&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;//////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;/////&nbsp;&nbsp;///&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;/&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;/////&nbsp;&nbsp;///&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;///&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;///&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\////&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|///&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/////&nbsp;&nbsp;////&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;///&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;////\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;|&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;//&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;|&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;//&nbsp;&nbsp;/////////&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;|&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;|&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;|&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;\\///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;///&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;\\///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;///&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;//////////&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;|&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;|&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;|&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;|&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;|&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;|&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;|&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;|&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;|&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;|&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;|&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;|&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/////&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;\\////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\////&nbsp;&nbsp;\\///&nbsp;&nbsp;\\///&nbsp;&nbsp;/////////////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|//&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;/\\&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;//////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;//////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;//////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|//////X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;\\////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\////&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;\\///&nbsp;&nbsp;///////////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;/&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|//&nbsp;&nbsp;/&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;///////////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;//\\&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;///////////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;///////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;///////////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;///////////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;///////////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|////X/////////////////////////////////////////////////////////////////0",
    delay: 50,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;\\////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\////&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;\\///&nbsp;&nbsp;/////////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|//&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;/////////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;///\\&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;/////////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;/////////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;/////////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|//X/////////////////////////////////////////////////////////////////0",
    delay: 750,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////X/////////////////////////////////////////////////////////////////0",
    delay: 250,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 0,
  },
];

const sequence6 = [
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X///&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X///////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;/////&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X///////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////////&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;/////&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X///////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////////&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////////&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;///////&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;////&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;////&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////////&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////////&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;////////&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;//////&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///////&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;////////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////////|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;/////////&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////////\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;/////&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///////&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////////&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////////\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////////\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;/////&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///////&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;////\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////////\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;/////////\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;////&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;/////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;///////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;////&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////////\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;/////////\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;////////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;////\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;////&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;/////////\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;////////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;/////&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;/////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;///////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;////\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;////////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;//////////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|/////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\/////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/////\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;/////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;//////////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\////&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/////&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;//////////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\/////////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;////&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//////\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////////&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\/////////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\////////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;////&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;/////&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\/////////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\////////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;////////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\////&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;/////&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\////////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\///////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;////////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////////&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;////&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\///////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;///////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////////&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;/////&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\//////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////////&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////////&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;/////&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;/////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;////&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////////&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////////&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;/////&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;/////////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/////////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////////&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////////&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////////&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;/////&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;/////////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/////////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;////&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////////&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////////&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////////\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;////&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;//////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/////////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;/////////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;////&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////////&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////////\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;//////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;/////////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;/////////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;////&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////////\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;////\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;/////////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;/////////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;////\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;/////&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;/////////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/////&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;//////|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////////&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;//////&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////////&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;//////&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\////&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\////\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;////&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;/////&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;//////&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\////&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\////////&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;//////////&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\/////&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\////////&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|////\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///////////&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;////&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\////////&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;////&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;/////&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|///////&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////////////&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;////\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;////&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;/////&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;/////&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////////\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;/////&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/////\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;////&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/////&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\////&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;////&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;////|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/////\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//////\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;////&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/////&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\////////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;////&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;////&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;////\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//////\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;///////\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;////&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\////////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//////////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;////&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;////&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//////\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;///////\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;///////|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\////////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//////////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;//////////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\////&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;////&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;///////\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;///////|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//////////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\/////////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;//////////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;////\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;///////|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;///////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|////////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;/////////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\//////////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;////|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;///////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\////////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;/////////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;////////////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;///////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;////////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\/////////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////////////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;///////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;////////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////////////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///////|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;///////&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\////////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///////|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////////////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;///////&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;///////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|////&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;//////////&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////////////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;///////////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;///////&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;////&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////////&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////////////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;///////////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;///////&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;////|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;////////////&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////////////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;///////////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;/////////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;//////\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;////&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;/////////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;////////&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;///////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|////\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;////\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;////&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////////&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;/////////&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;////////&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;//////&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;/////&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;/////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;/////////////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;/////////////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;/////////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;//////&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;/////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/////&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;/////////////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;/////////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;//////////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//////&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/////&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;////////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;////&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\////&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\////////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;///////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\////////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;////////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;///////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;/////&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;////&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\////////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;////////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;///////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;///////&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;/////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;////////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///////&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;/////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///////&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;////&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////////&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/////&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;/////&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///////&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;//////&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///////&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;////&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;////&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;///////&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///////&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;////////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;////&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;//////////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;//////////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///////&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;////////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/////////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;//////////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;////&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;/////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;////////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/////////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;//////////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;/////////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;/////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/////////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;/////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;/////////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;/////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;/////////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;//////////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;//////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;//////////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;//////////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;/////&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;//////////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;//////////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////////X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;/////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;//////////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////////X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;/////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////////X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;///X/////////////////////////////////////////////////////////////////0",
    delay: 13,
  },
  {
    text: "0/////////////////////////////////////////////////////////////////X//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;\\///&nbsp;&nbsp;///////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;|/&nbsp;&nbsp;&nbsp;\\//&nbsp;&nbsp;//////&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////////&nbsp;&nbsp;&nbsp;&nbsp;\\|&nbsp;&nbsp;//////&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;&nbsp;&nbsp;|////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;\\&nbsp;&nbsp;\\&nbsp;&nbsp;//////&nbsp;&nbsp;//&nbsp;&nbsp;/&nbsp;&nbsp;////&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\\///&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;/\\&nbsp;&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;/\\&nbsp;&nbsp;\\//&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;/////&nbsp;&nbsp;//&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;\\/&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;\\//&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;///\\&nbsp;&nbsp;//////&nbsp;&nbsp;&nbsp;////&nbsp;&nbsp;//&nbsp;&nbsp;////&nbsp;&nbsp;////&nbsp;&nbsp;///\\&nbsp;&nbsp;\\&nbsp;&nbsp;////&nbsp;&nbsp;//\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;//&nbsp;&nbsp;///|&nbsp;&nbsp;/|&nbsp;&nbsp;|/\\&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;////&nbsp;&nbsp;//X/////////////////////////////////////////////////////////////////0",
    delay: 0,
  },
];

export const sequences = [
  sequence1,
  sequence2,
  sequence3,
  sequence4,
  sequence5,
  sequence6,
];
// export const sequences = [sequence4];
