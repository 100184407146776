import AutoBind from "auto-bind";
import Preloader from "./components/Preloader";
import Everything from "./pages/Everything";
import Contact from "./pages/Contact";
import Initial from "./pages/Initial";
import Experience from "./pages/Experience";
import Tools from "./pages/Tools";
import Projects from "./pages/Projects";
import Credits from "./pages/Credits";
import Invalid from "./pages/Invalid";
import Time from "./components/Time";
import Year from "./components/Year";
import Characters from "./components/Characters";

class App {
  constructor() {
    AutoBind(this);

    this.mouse = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    };

    this.createPreloader();
    this.createTime();
    this.createCharacters();

    this.pages = {
      0: new Initial(),
      1: new Experience(),
      2: new Tools(),
      3: new Projects(),
      4: new Contact(),
      5: new Everything(),
      6: new Credits(),
      invalid: new Invalid(),
    };

    // if (this.url.indexOf("/case") > -1) {
    //   this.page = this.case;
    //   this.page.onResize();
    // } else {
    // }

    this.page = this.pages["0"];

    // this.page.show();

    this.addEventListeners();

    this.onResize();
  }

  createPreloader() {
    this.preloader = new Preloader();

    this.preloader.once("completed", this.onPreloaded.bind(this));
  }

  onPreloaded() {
    this.onResize();
    this.page.show();
  }

  createTime() {
    this.time = new Time();
    this.year = new Year();
  }

  createCharacters() {
    this.characters = new Characters();
  }

  async onChange({ url = null }) {
    url = url.replace(window.location.origin, "");

    this.isFetching = true;

    this.url = url;

    await this.page.hide();

    this.page = this.pages[this.url];

    this.onResize();
    await this.page.show(this.url);
    this.toggleBlink();

    this.isFetching = false;
  }

  /**
   * Events
   */

  onResize() {
    if (this.page) {
      this.page.onResize();
    }
  }

  toggleBlink() {
    const activePageInput = document.querySelector(".page--active input");

    setTimeout(() => {
      if (activePageInput.value.length) {
        activePageInput.classList.remove("blink");
      } else {
        activePageInput.classList.add("blink");
      }
    }, 0);
  }

  onKeyDown(event) {
    const key = event.key;

    if (key === "Enter") {
      this.submitHandler();
      return;
    }

    if (key === "Tab" || key === "Shift" || key === "Escape") {
      return;
    }

    this.toggleBlink();

    const activePageInput = document.querySelector(".page--active input");

    const isFocused = activePageInput === document.activeElement;
    !isFocused && activePageInput.focus();

    if (key === " ") {
      activePageInput.blur();
    }
  }

  submitHandler() {
    const activePageInput = document.querySelector(".page--active input");

    activePageInput.value && this.switchPage(activePageInput.value);
  }

  switchPage(keyword) {
    const isCorrectInput = ["1", "2", "3", "4", "5", "6"].includes(keyword);
    const activePageInput = document.querySelector(".page--active input");
    activePageInput.blur();

    if (isCorrectInput) {
      activePageInput.value = "";
      this.onChange({ url: keyword });
    } else {
      activePageInput.value = "";
      this.onChange({ url: "invalid" });
    }
  }

  addEventListeners() {
    window.addEventListener("resize", this.onResize, { passive: true });
    window.addEventListener("keydown", this.onKeyDown, { passive: true });
  }
}

new App();
