import AutoBind from "auto-bind";
import { mapEach, mapElements } from "../utils/dom";
import { delay, blockDelay, nestedDelay } from "../utils/math";

export default class Page {
  constructor({ classes, element, elements }) {
    AutoBind(this);

    this.classes = {
      ...classes,
    };

    this.selector = element;
    this.selectorChildren = { ...elements };
    this.create();
  }

  create() {
    if (this.selector instanceof HTMLElement) {
      this.element = this.selector;
    } else {
      this.element = document.querySelector(this.selector);
    }

    this.elements = {};

    Object.keys(this.selectorChildren).forEach((key) => {
      const entry = this.selectorChildren[key];

      if (
        entry instanceof HTMLElement ||
        entry instanceof NodeList ||
        Array.isArray(entry)
      ) {
        this.elements[key] = entry;
      } else {
        this.elements[key] = this.element.querySelectorAll(entry);

        if (this.elements[key].length === 0) {
          this.elements[key] = null;
        } else if (this.elements[key].length === 1) {
          this.elements[key] = this.element.querySelector(entry);
        }
      }
    });
  }

  async show() {
    let delayTime = 0;

    const elements = mapElements(this.element, {
      blocks: ".c-section",
      footer: ".c-section__footer",
      nav: ".c-nav",
      form: ".c-form",
    });

    const { blocks, footer, nav, form } = elements;

    blocks.style.cssText = "opacity: 0;";
    footer.style.cssText = "opacity: 0;";
    nav.style.cssText = "opacity: 0;";
    form.style.cssText = "opacity: 0;";

    delayTime += blockDelay + nestedDelay;

    await delay(delayTime);
    blocks.style.cssText = "opacity: 1;";

    await delay(delayTime);
    footer.style.cssText = "opacity: 1;";

    await delay(1250);
    nav.style.cssText = "opacity: 1;";

    await delay(250);
    form.style.cssText = "opacity: 1;";
  }

  hide() {
    return Promise.resolve();
  }

  /**
   * Events
   */

  onResize() {
    // this.scroll.limit = this.elements.wrapper.clientHeight - window.innerHeight;
  }
}
